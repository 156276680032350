import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const PrivacyContainer = styled.div`
  display: grid;
  grid-template-columns: 1000px;
  justify-content: center;
  color : #33475b;
  margin : 60px 0;
  text-align: justify;
  @media (max-width: 1550px) {
    grid-template-columns: 55%
  }
  @media (max-width: 960px) {
    grid-template-columns: 70%;
  }
  @media (max-width: 767px) {
    grid-template-columns: auto;
    padding : 0 20px;
    margin-top : 20px;
  }
`
export const PrivacyTitle = styled.h1`
    font-size : 42px;
    text-align : center;
    margin-bottom : 40px;
    @media (max-width: 575px){
    font-size: 23px;
  }
`
export const PrivacySubTitle = styled.h4`
    font-size : 24px;
    margin : 15px 0 8px 0;
`
export const PrivacyText = styled.p`
    font-size : 16px;
    line-height: 1.5;
    @media (max-width: 1450px){
    font-size : 15px;
  }
`
export const PrivacyItalic = styled.p`
    font-size : 16px;
    text-align : center;
    font-style: italic;
    @media (max-width: 1450px){
    font-size : 15px;
  }
`
export const TermsList = styled.li`
    font-size : 16px;
    line-height: 1.4;
    @media (max-width: 1450px){
    font-size : 15px;
    margin: 11px 0;
  }
`
export const TermUl = styled.ul`
  margin : .4em 0 1em 0;
`
