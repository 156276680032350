import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import {
  PrivacyContainer,
  PrivacyTitle,
  PrivacySubTitle,
  PrivacyText,
  PrivacyItalic,
  TermsList,
  TermUl
} from "../components/privacyTerms"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #33cdd1;
`

export default ({location}) => (
  <>
    <SEO title="Privacy Policy" description="Privacy policy of Groupboss" pathname={location.pathname} />
    <PrivacyContainer>
      <PrivacyTitle>Privacy Policy</PrivacyTitle>
      <PrivacyText>
        See our Terms of Service{" "}
        <span>
          <StyledLink to="/">(https://groupboss.io/) </StyledLink>
        </span>{" "}
        for additional information. You are agreeing to this Privacy Policy
        implies that you also agree to our Terms of Service.
      </PrivacyText>
      <PrivacySubTitle>
        1. User’s Personal information collection by GroupBoss
      </PrivacySubTitle>
      <TermUl>
        <TermsList>
          Singularity Corporation. (“Group Boss”) is keen on data security as we
          are well aware that our users (you) care about how their personal
          information is used. Hosted on high performing Secure Enterprise Level
          clouds, we assure you that all our customer information is held
          confidential. We never sell our customer list or customer information.
        </TermsList>
        <TermsList>
          All the customer information collected such as name, email address,
          phone number, website, and social media handles, mailing address,
          billing information or any other information related to third-party
          integrations is collected for the sole purpose of providing best
          services to our customers and to update them of the improvements to
          our product and services.
        </TermsList>
        <TermsList>
          Group Boss collects data and Personal Information in two places, the
          website (groupboss.io) and any of our input clients (This Could be
          Mac, Windows, Chromebook, Browser Extension, Linux, Android, and iOS).
        </TermsList>
        <TermsList>
          Group Boss collects, generates and receives information in a variety
          of ways when you use the Service. Some of this information constitutes
          Personal Data.
        </TermsList>
      </TermUl>

      <PrivacyText>The information you provide:</PrivacyText>
      <PrivacyText>
        You provide us with information containing your Personal Data as a User
        of Group Boss Services. Upon registration and creating your profile you
        provide us ,without limitation to the following information:
      </PrivacyText>
      <TermUl>
        <TermsList>
          Name, email, user name, password, phone (optional), and Skype handle
          (optional).
        </TermsList>
      </TermUl>
      <PrivacyText>Information generated when using the Service:</PrivacyText>
      <TermUl>
        <TermsList>
          Some of the information processed by Group Boss is created by you
          using the Service, and this information may also contain Personal
          Data.
        </TermsList>
      </TermUl>
      <PrivacyText>
        Group Boss may record following information, without limitation , when
        you use the Service:
      </PrivacyText>
      <TermUl>
        <TermsList>
          IP address where you logged in, browser type and browser software
          version, names of applications used, names of tasks to be worked on,
          websites visited.
        </TermsList>
      </TermUl>

      <PrivacySubTitle>2. Disclosure of Personal Information</PrivacySubTitle>
      <PrivacyText>
        Personal Information about our customers is an integral part of our
        business. We neither rent nor sell your Personal Information to anyone.
        We share your Personal Information only as described immediately below.
      </PrivacyText>
      <PrivacyText>
        Data Access: If you use Group Boss in your capacity as an employee, your
        employer has direct access to your data. If you are an independent
        contractor, the person or entity with whom you contract has direct
        access to your data. In addition, Group Boss employees may have access
        to your data for testing or the general improvement of our software, to
        notify you about changes to our Website or any products or services we
        offer or provide through it, or to fulfill the purpose for which you
        provided it.
      </PrivacyText>
      <PrivacyText>
        For Business purpose: Group Boss may use your personal information to
        contact you via mail, email, or telephone in order to give you updates
        about Group Boss’s special events, new services, current information
        regarding our products, or other promotions that may be of interest to
        you. If you are a business or organization that has scheduled a
        conference, event, or meeting at one of our facilities, we may also
        share your personal information with our event organizers. We also use
        return email addresses to answer the email we receive from you and use
        for our own marketing purposes and update you about our products. Your
        email address will not be used for any other purpose or shared with
        outside third parties for their direct marketing purposes. We may also
        use your IP address to help protect Group Boss and our Internet Service
        Providers from fraud.
      </PrivacyText>
      <PrivacyText>
        Sharing personal information: At times Group Boss may use the services
        of independent companies to provide certain services to you, including,
        without limitation, website hosting services, mailing services, and
        credit card processing, (“Internet Service Providers”). Group Boss may
        share your personal information with the Internet Service Providers as
        appropriate. Information we share with our Service Providers may include
        both information you provide to us and information we collect about you,
        including personal data and information from data collection tools like
        cookies, web beacons, and log files.
      </PrivacyText>
      <PrivacyText>
        Additional uses of your non-personal and personal information will allow
        us to tailor products and services specific to your needs, to help
        organize and manage our relationship with you or your business, to
        conduct business, to provide you with customer and guest support, to
        perform functions that are described to you at the time of collection,
        and to enforce our Website’s Terms of Use.
      </PrivacyText>
      <PrivacyText>
        Agents: We may employ other companies and people to perform tasks on our
        behalf and need to share your information with them to provide products
        or services to you. Unless we tell you differently, Group Boss’s agents
        do not have any right to use Personal Information we share with them
        beyond what is necessary to assist us.
      </PrivacyText>
      <PrivacyText>
        Protection of Group Boss and Other Parties: Group Boss may also disclose
        your personal information as is necessary to comply with a court order
        or enforce or apply our Terms and Conditions and other agreements; or
        protect the rights, property, or safety of Group Boss, our users, or
        others. We may disclose your information in connection with the sale or
        merger of Group Boss or any transaction that involves the sale or
        assignment of some or all of our assets.
      </PrivacyText>
      <PrivacyText>
        Account administrators: Every Group Boss account may have one or more
        account administrators who can configure and manage your Group Boss
        account on behalf of you and your organization. You hereby consent to
        our sharing of Personal Information with your account administrators.
      </PrivacyText>

      <PrivacySubTitle>
        3. Safeguarding your personal information
      </PrivacySubTitle>
      <PrivacyText>
        Group Boss makes reasonable efforts to safeguard your personal
        information and to help protect against unauthorized access and
        disclosure. Only our authorized personnel and our Internet Service
        Providers who perform legitimate business functions for Group Boss are
        authorized to access your personal information. Despite our efforts, the
        Internet has inherent security risks. Group Boss cannot guarantee, and
        you should not expect, that your personal information, personal
        searches, and other communications will always remain secure. You should
        be careful while handling and disclosing your personal information or
        any username or password that you are required to use to access services
        on our Websites.
      </PrivacyText>

      <PrivacySubTitle>4. Conditions of use</PrivacySubTitle>
      <PrivacyText>
        If you decide to visit the Group Boss website, your visit and any
        possible dispute over privacy is subject to this Privacy Policy and our
        Terms of Use, including limitations on damages, arbitration of disputes,
        and application of Delaware state laws.
      </PrivacyText>
      <PrivacyText>
        Please note that you could be directed to another site while using Group
        Boss. When you link to another site, you should review their privacy
        policy, as it may be different from this Privacy Policy. Such sites may
        send their own cookies to users and otherwise collect data or solicit
        personal information.
      </PrivacyText>
      <PrivacyText>
        Group Boss is not responsible, and shall not be liable for the privacy
        practices of linked sites or any use such sites may make of any
        information collected from you. This Privacy Policy applies solely to
        information collected by Group Boss.
      </PrivacyText>
      <PrivacyText>
        In case of any confusion, Group Boss has the sole right to interpret
        this agreement.
      </PrivacyText>

      <PrivacySubTitle>5. Data Protection</PrivacySubTitle>
      <PrivacyText>
        Any information that is stored in our system as a result of the usage of
        Groupboss services is regarded as user data. This could be your personal
        or non personal information. Non personal information are your
        customers, members, business, contacts, contractor, suppliers etc
        information that are stored in the Groupboss system as a result of the
        use of the services of groupboss.
      </PrivacyText>
      <PrivacyText>
        Groupboss absolutely does not sell or share user’s data to any third
        party companies.
      </PrivacyText>
      <PrivacyText>
        However Groupboss reserves the right to use your non-personal and
        personal information case by case to allow us to tailor products and
        services specific to your needs, to help organize and manage our
        relationship with you or your business, to conduct business, to provide
        you with customer and guest support, for marketing, sales and promotion
        and to perform functions that are described to you at the time of
        collection, and to enforce our Website’s Terms of Use.
      </PrivacyText>

      <PrivacySubTitle>6. Data retention policy</PrivacySubTitle>
      <PrivacyText>
        Groupboss has a data retention policy and the system might not store
        user’s data perpetually. User’s data might be deleted after a certain
        time interval ranges from 1 month to 3 years or more. These policies are
        dependent on types of data and are subjected to change from time to
        time. It's the user's responsibility to keep updated with the most
        recent data retention policy.
      </PrivacyText>

      <PrivacySubTitle>7. Changes to this privacy policy</PrivacySubTitle>
      <PrivacyText>
        Group Boss may amend this Privacy Policy from time to time. Use of
        information we collect now is subject to the Privacy Policy in effect at
        the time such information is used. If we make changes in the way we use
        Personal Information, we will notify you by posting an announcement on
        our Website or sending you an email. Your continued use of the services
        after the effective date of any posted change constitutes your
        acceptance of the amended Privacy Policy as modified by the posted
        changes. For this reason, we encourage you to review this Privacy Policy
        whenever you use the services and check your emails from Group Boss. The
        last date this Privacy Policy was revised is set forth below.
      </PrivacyText>
      <PrivacyText>
        Group Boss may amend this Privacy Policy from time to time. Use of
        information we collect now is subject to the Privacy Policy in effect at
        the time such information is used. If we make changes in the way we use
        Personal Information, we will notify you by posting an announcement on
        our Website or sending you an email. Your continued use of the services
        after the effective date of any posted change constitutes your
        acceptance of the amended Privacy Policy as modified by the posted
        changes. For this reason, we encourage you to review this Privacy Policy
        whenever you use the services and check your emails from Group Boss. The
        last date this Privacy Policy was revised is set forth below.
      </PrivacyText>
      <PrivacyItalic>Last updated : 11 March 2020</PrivacyItalic>
    </PrivacyContainer>
  </>
)
